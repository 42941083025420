import { getNestedValue, setNestedValue } from './utils/index.js'
import { createMallTagsInfo, createBeltLabel, createPriceRelatedData, createDiscountInfo, createHistoricalPrice, createLocateLabels, createRankList, createSellingPointUniversalLabels, createHorseRaceLabelData, createServiceLabelsData, createTrendLabel, createMaskLayer, createSizeStock, createSaleAttr, createReducePrice, createSuggestedSalePriceConfigInfo, createMixPromotionInfo, createSearchFilters, createExt, createStoreInfo, createBrandDealsLabelData } from './childDataTrans.js'


// 对应web中间层以及bff层的商品数据Key值
// key: bff层  value: web中间层

// 忽略的字段（商卡不用）： detail_url  appPromotion score  brand

// 未处理的字段：isHypernymGoods
const goodsTransformationKeys = [
  // [bffKey  webKey]
  ['type', 'type'], // 修复点后推融合BUG，因为这个字段被过滤了，之后再优化，先临时解决问题
  ['ext', 'ext'],
  ['dynamic_ext', 'dynamic_ext'],
  ['mall_code', 'mall_code'],
  ['goods_id', 'goods_id'],
  ['goods_sn', 'goods_sn'],
  ['goods_img', 'goods_img'],
  ['_imgThumb', '_imgThumb'],
  ['goods_thumb', 'goods_img_thumb'],
  ['goods_name', 'goods_name'],
  ['goods_url_name', 'goods_url_name'],
  ['productRelationID', 'productRelationID'],
  ['cat_id', 'cat_id'],
  ['cate_name', 'cat_name'],
  ['parent_id', 'parent_id'],
  ['detail_image', 'detail_image'],
  ['is_on_sale', 'is_on_sale'],
  ['is_saved', 'is_saved'],
  ['stock', 'stock'],
  ['store_code', 'store_code'],
  ['isNewCoupon', 'isNewCoupon'],
  ['promotionInfo', 'promotionInfo'],
  ['count', 'count'],
  ['isHasBigSize', 'is_show_plus_size'],
  ['mallPromotionInfo', 'mallPromotionInfo'],
  ['mallInfoList', 'mallInfoList'],
  ['storeName', 'storeName'],
  ['unit', 'unit'],
  ['unitBury', 'unitBury'],
  ['isSrpInversion', 'isSrpInversion'],

  // 是否显示加车按钮
  ['productMaterial.showAddButtonLabel', 'showAddButtonLabel'],
  // 一键购
  ['productMaterial.showAddButtonLabelStyle', 'showAddButtonLabelStyle'],

  // 标题前角标 start
  ['productMaterial.productTitleSubscript.labelLang', 'mobileVerticalView.goodsNameLabel.labelLang'],
  ['productMaterial.productTitleSubscript.fontColor', 'mobileVerticalView.goodsNameLabel.fontColor'],
  ['productMaterial.productTitleSubscript.backgroundColor', 'mobileVerticalView.goodsNameLabel.backgroundColor'],
  ['productMaterial.productTitleSubscript.appTraceInfo', 'mobileVerticalView.goodsNameLabel.appTraceInfo'],
  ['productMaterial.productTitleSubscript.labelLang', 'mobileBroadwiseView.goodsNameLabel.labelLang'],
  ['productMaterial.productTitleSubscript.fontColor', 'mobileBroadwiseView.goodsNameLabel.fontColor'],
  ['productMaterial.productTitleSubscript.backgroundColor', 'mobileBroadwiseView.goodsNameLabel.backgroundColor'],
  ['productMaterial.productTitleSubscript.appTraceInfo', 'mobileBroadwiseView.goodsNameLabel.appTraceInfo'],
  // 标题前角标 end


  // 价格相关 start
  ['discountPrice', 'discountPrice'],
  ['unit_discount', 'unit_discount'],
  ['salePrice', 'salePrice'],
  ['unitPrice', 'unitPrice'],
  ['retailPrice', 'retailPrice'],
  ['suggestedSalePriceInfo', 'suggestedSalePriceInfo'],
  ['reducePrice', 'reducePrice'],
  ['productMaterial.hisLowPriceLabel', createHistoricalPrice], // 最低价
  ['isInversion', 'isInversion'],  // 是否倒挂
  // ['suggestedSalePrice', 'suggestedSalePrice'],  // 合规价
  ['srpPriceInfo', 'srpPriceInfo'], // 合规站点 建议零售价 RRP价
  ['srpDiscountPrice', 'srpDiscountPrice'],
  ['srpDiscount', 'srpDiscount'],
  ['srpPrice', 'srpPrice'],
  // 价格相关 end

  // 销量标签
  ['productMaterial.salesLabel.backgroundColor', 'mobileVerticalView.salesLabels.backgroundColor'],
  ['productMaterial.salesLabel.fontColor', 'mobileVerticalView.salesLabels.fontColor'],
  ['productMaterial.salesLabel.labelLang', 'mobileVerticalView.salesLabels.labelLang'],
  ['productMaterial.salesLabel.appTraceInfo', 'mobileVerticalView.salesLabels.appTraceInfo'],
  ['productMaterial.salesLabel.icon', 'mobileVerticalView.salesLabels.icon'],
  ['productMaterial.salesLabel.backgroundColor', 'mobileBroadwiseView.salesLabels.backgroundColor'],
  ['productMaterial.salesLabel.fontColor', 'mobileBroadwiseView.salesLabels.fontColor'],
  ['productMaterial.salesLabel.labelLang', 'mobileBroadwiseView.salesLabels.labelLang'],
  ['productMaterial.salesLabel.appTraceInfo', 'mobileBroadwiseView.salesLabels.appTraceInfo'],
  ['productMaterial.salesLabel.icon', 'mobileBroadwiseView.salesLabels.icon'],

  // buybox入口
  ['productMaterial.buyBox.lowestPrice', 'buyBoxPrice.price'],
  ['productMaterial.buyBox.sameKindNum', 'buyBoxCount.count'],
  ['business_model', 'business_model'],
  ['quickship', 'quickship'],

  // wiki pageId=1477051455， 信息流内容体用的
  ['productMaterial.userBehaviorLabel.icon', 'mobileVerticalView.userBehaviorLabels.icon'],
  ['productMaterial.userBehaviorLabel.labelLang', 'mobileVerticalView.userBehaviorLabels.labelLang'],
  ['productMaterial.userBehaviorLabel.fontColor', 'mobileVerticalView.userBehaviorLabels.fontColor'],
  ['productMaterial.userBehaviorLabel.backgroundColor', 'mobileVerticalView.userBehaviorLabels.backgroundColor'],
  ['productMaterial.userBehaviorLabel.description', 'mobileVerticalView.userBehaviorLabels.description'],
  ['productMaterial.userBehaviorLabel.pictureBeltText', 'mobileVerticalView.userBehaviorLabels.pictureBeltText'],
  ['productMaterial.userBehaviorLabel.appTraceInfo', 'mobileVerticalView.userBehaviorLabels.appTraceInfo'],
  ['productMaterial.userBehaviorLabel.icon', 'mobileBroadwiseView.userBehaviorLabels.icon'],
  ['productMaterial.userBehaviorLabel.labelLang', 'mobileBroadwiseView.userBehaviorLabels.labelLang'],
  ['productMaterial.userBehaviorLabel.fontColor', 'mobileBroadwiseView.userBehaviorLabels.fontColor'],
  ['productMaterial.userBehaviorLabel.backgroundColor', 'mobileBroadwiseView.userBehaviorLabels.backgroundColor'],
  ['productMaterial.userBehaviorLabel.description', 'mobileBroadwiseView.userBehaviorLabels.description'],
  ['productMaterial.userBehaviorLabel.pictureBeltText', 'mobileBroadwiseView.userBehaviorLabels.pictureBeltText'],
  ['productMaterial.userBehaviorLabel.appTraceInfo', 'mobileBroadwiseView.userBehaviorLabels.appTraceInfo'],
  // buybox弹窗
  ['isHighestSales', 'isHighestSales'],

  // 收藏用  
  ['skuAttrList', 'sku_attr_list'],
  ['inventory', 'inventory'],
  ['wishlistId', 'wishlistId'],
  ['sale_status', 'sale_status'],
  ['isSize', 'isSize'],
  ['count', 'count'],
  ['sku_code', 'sku_code'],
  ['originalAttrValueId', 'attrValueId'],
  ['groupGoodsStat', 'inGroup'],
  // 浏览人数标签-弹幕组件
  ['productMaterial.numberOfVisitorsLabel', 'pretreatInfo.numberOfVisitorsLabel'],
  // 收藏轮播
  ['productMaterial.popularLabelList', 'pretreatInfo.popularLabelList'],
  ['cccFeedback', 'cccFeedback'],
  // 个人中心足迹列表用
  ['isNewProductUnSale', 'new_product_unsale'],

  // 商详新搭配组合购sku切换
  ['skcImageUrl', 'skcImageUrl'],
  ['skuCombineName', 'skuCombineName'],
  ['sku_code', 'sku_code'],
  ['multiLevelSaleAttribute', 'multiLevelSaleAttribute'],
  ['promotionGroup', 'promotion_group'],

  // 点后刷
  ['divideTime', 'divideTime'],
  ['isRefresh', 'isRefresh'],

  // 品牌信息
  ['premiumFlagNew', 'premiumFlagNew'],

  // 视频信息
  ['video_infos', 'video_infos'],
]

/**
 * 
 * @param {*} bffGoodsInfo 
 * @param {{
 *  lang: String //当前站点语言
 *  language: Object //多语言
 *  isPaid: Boolean //是否付费会员
 *  suggestedSaleType: String //当前站点合规配置
 *  hideSuggestedSalePrice: Boolean //是否隐藏合规价
 *  inStock: Boolean // 转换的商品是否是否一定有库存，会伪造库存和在售字段
 * }} options 
 * @returns goodsInfo
 */
export const goodsTransformation = (bffGoodsInfo = {}, options = {}) => {
  const goodsInfo = {
    // 标识，商卡内部需要针对两端不同的特殊处理
    bffGoodsInfo: true,
    suggestedSaleType: options?.suggestedSaleType ?? '',
    mobileVerticalView: {},
    mobileBroadwiseView: {},
    pretreatInfo: {
      mobileVerticalView: {},
      mobileBroadwiseView: {}
    }
  }

  goodsTransformationKeys.forEach(([bffKey, webKey]) => {
    const bffValue = getNestedValue(bffGoodsInfo, bffKey)

    if (bffValue !== undefined && bffValue !== null) {
      if (Object.prototype.toString.call(webKey) === '[object Function]') {
        webKey(bffGoodsInfo, goodsInfo)
      } else {
        setNestedValue(goodsInfo, webKey, bffValue)
      }
    }
  })

  // 一些需要转格式的字段
  if (goodsInfo.goods_id) {       
    goodsInfo.goods_id = Number(goodsInfo.goods_id)
  }

  if (goodsInfo.stock) {
    goodsInfo.stock = Number(goodsInfo.stock)
  } else if (options?.inStock) {
    goodsInfo.stock = 999
  } else {
    goodsInfo.stock = 0
  }
  
  if (goodsInfo.is_on_sale) {
    goodsInfo.is_on_sale = Number(goodsInfo.is_on_sale)
  } else if (options?.inStock) {
    goodsInfo.is_on_sale = 1
  }  else {
    goodsInfo.is_on_sale = 0
  }

  if (goodsInfo.cat_id) {
    goodsInfo.cat_id = Number(goodsInfo.cat_id)
  }

  if(goodsInfo.is_saved){
    goodsInfo.is_saved = Number(goodsInfo.is_saved)
  }

  // 合规价处理
  // 为了兼容中间层接口 不一定再最外层返回suggestedSalePrice（例如收藏的分享结果页）
  goodsInfo.suggestedSalePrice = bffGoodsInfo.suggestedSalePrice || bffGoodsInfo.suggestedSalePriceInfo?.suggestedSalePrice

  // 埋点数据
  const extData = createExt(bffGoodsInfo)
  goodsInfo?.ext && (goodsInfo.ext.other_ext_mark = extData.ext_other_ext_mark)
  goodsInfo?.dynamic_ext && (goodsInfo.dynamic_ext.other_d_ext_mark = extData.dynamic_ext_other_ext_mark)

  // spu图
  if (bffGoodsInfo?.spu_images?.spu_images) {
    goodsInfo.spu_image = bffGoodsInfo?.spu_images?.spu_images.map(v => {
      return {
        medium_image: v
      }
    }).filter( v => v.medium_image)
  }

  // 收藏找相似弹窗字段处理
  if (bffGoodsInfo?.similar_products?.length) {
    goodsInfo.similar_products = bffGoodsInfo.similar_products.map(v => {
      v.stock = 999
      v.is_on_sale = 1
      return goodsTransformation(v, options)
    })
  }

  // mall信息
  if (bffGoodsInfo.mallInfoList?.length > 0 && bffGoodsInfo.mall_code) {
    const mallTagsInfoRes = createMallTagsInfo(bffGoodsInfo)

    goodsInfo.pretreatInfo.mallTagsInfo = mallTagsInfoRes?.mallTagsInfo
    goodsInfo.pretreatInfo.localMallReady = mallTagsInfoRes?.localMallReady
  }

  // 商品对应店铺信息
  if (bffGoodsInfo?.storeInfo) {
    goodsInfo.storeInfo = {
      ...bffGoodsInfo.storeInfo,
      title: bffGoodsInfo.storeInfo?.storeName
    }
  }

  // 主图角标处理
  const locateLabels = createLocateLabels(bffGoodsInfo)
  goodsInfo.mobileVerticalView.locateLabels = locateLabels.mobileVerticalView
  goodsInfo.mobileBroadwiseView.locateLabels = locateLabels.mobileBroadwiseView

  // 活动价格的处理 到手价 闪购价 会员价
  const priceData = createPriceRelatedData(bffGoodsInfo, options)

  goodsInfo.unitDiscountString = priceData.unitDiscountString ?? ''
  goodsInfo.estimatedPriceInfo = priceData.estimatedPriceInfo ?? {}
  goodsInfo.sheinClubPromotionInfo = priceData.sheinClubPromotionInfo ?? {}
  goodsInfo.exclusivePromotionInfo = priceData.exclusivePromotionInfo ?? {}
  goodsInfo.exclusivePromotionPrice = priceData.exclusivePromotionPrice ?? {}
  goodsInfo.flashZonePromotion = priceData.flashZonePromotion ?? {}
  goodsInfo.srpDiscountPercentString = priceData.srpDiscountPercentString ?? ''
  goodsInfo.pretreatInfo.newFlashPromotion = priceData.newFlashPromotion ?? {}
  // 是否命中普通闪购商品
  goodsInfo.pretreatInfo.flashsale = priceData?.flashsale ?? 0

  // 折扣标签
  goodsInfo.pretreatInfo.discountInfo = createDiscountInfo(bffGoodsInfo, goodsInfo, options) || {}

  // 主图腰带处理
  const beltLabel = createBeltLabel(bffGoodsInfo, goodsInfo, options)
  goodsInfo.pretreatInfo.mobileVerticalView.beltLabel = beltLabel.mobileVerticalView
  goodsInfo.pretreatInfo.mobileBroadwiseView.beltLabel = beltLabel.mobileBroadwiseView

  // 榜单处理
  const rankListData = createRankList(bffGoodsInfo)

  goodsInfo.pretreatInfo.mobileVerticalView.rankingList = rankListData.mobileVerticalView
  goodsInfo.pretreatInfo.mobileBroadwiseView.rankingList = rankListData.mobileBroadwiseView

  // 卖点轮播处理
  if (bffGoodsInfo.productMaterial?.productAttributeLabelList?.length > 0) {
    const sellingPointUniversalLabels = createSellingPointUniversalLabels(bffGoodsInfo)
    goodsInfo.pretreatInfo.mobileVerticalView.sellingPointUniversalLabels = sellingPointUniversalLabels.mobileVerticalView
    goodsInfo.pretreatInfo.mobileBroadwiseView.sellingPointUniversalLabels = sellingPointUniversalLabels.mobileBroadwiseView
  }

  // 赛马标签轮播处理
  if (bffGoodsInfo.productMaterial?.v2ProductAttributeLabelList?.length > 0) {
    goodsInfo.pretreatInfo.horseRaceLabel = createHorseRaceLabelData(bffGoodsInfo)
  }

  // 客户端预处理中的促销标签字段 相关处理（参见public/src/services/goodsItemInfo/goodsPretreatInfo.js 中的setPromotionLabel）
  let mixPromotionInfo, promotionReady
  if(bffGoodsInfo?.promotionInfo?.length > 0) {
    ({ mixPromotionInfo, promotionReady } = createMixPromotionInfo(bffGoodsInfo))
    goodsInfo.pretreatInfo.mixPromotionInfo = mixPromotionInfo
    goodsInfo.pretreatInfo.promotionReady = promotionReady
  }

  // 服务类标签处理
  if (bffGoodsInfo.productMaterial?.productServiceLabelList?.length > 0) {
    const serviceLabels = createServiceLabelsData(bffGoodsInfo, { mixPromotionInfo })

    goodsInfo.pretreatInfo.mobileVerticalView.serviceLabels = serviceLabels
    goodsInfo.pretreatInfo.mobileBroadwiseView.serviceLabels = serviceLabels
  }

  // 趋势词 时尚店铺处理
  if (bffGoodsInfo.productMaterial?.trendLabel?.trendType) {
    const trendLabel = createTrendLabel(bffGoodsInfo, options) || {}

    if (trendLabel?.storeInfo) {
      goodsInfo.storeInfo = Object.assign({}, goodsInfo?.storeInfo ?? {}, trendLabel.storeInfo)
    }
    if (trendLabel?.deliveryInfo) {
      goodsInfo.deliveryInfo = trendLabel.deliveryInfo
    }
    // 中间层融合接口原词返回
    goodsInfo.trendLabel = bffGoodsInfo.productMaterial?.trendLabel || {}
  }

  if (bffGoodsInfo?.storeInfo) {
    const storeInfo = createStoreInfo(bffGoodsInfo, goodsInfo)
    if (goodsInfo.storeInfo) {
      goodsInfo.storeInfo = Object.assign({}, goodsInfo.storeInfo, storeInfo ?? {})
    }
  }

  // 主图蒙层
  if (bffGoodsInfo.productMaterial?.maskLayer?.image) {
    const maskLayer = createMaskLayer(bffGoodsInfo)
    goodsInfo.mobileVerticalView.maskLayer = maskLayer
    goodsInfo.mobileBroadwiseView.maskLayer = maskLayer
  }

  // 合规站点处理
  if (bffGoodsInfo.suggestedSaleType || goodsInfo.suggestedSaleType) {
    goodsInfo.pretreatInfo.suggestedSalePriceConfigInfo = createSuggestedSalePriceConfigInfo(bffGoodsInfo, options)
  }

  // 分布式筛选
  if (bffGoodsInfo.attrs?.length > 0) {
    goodsInfo.pretreatInfo.searchFilters = createSearchFilters(bffGoodsInfo)?.searchFilters ?? []
    goodsInfo.pretreatInfo.searchFiltersReady = true
  }

  // 收藏处理部分 start
  if (bffGoodsInfo.sku_code && bffGoodsInfo.skuInventoryList?.length > 0) {
    const stockData = createSizeStock(bffGoodsInfo, options)
    goodsInfo.sizeStock = stockData.sizeStock
    goodsInfo.pretreatInfo.stockLeft = stockData.stockLeft
    goodsInfo.pretreatInfo.stockLeftReady = true
  }

  // 主图右下角尺码
  if (bffGoodsInfo.skuAttrStr) {
    goodsInfo.pretreatInfo.saleAttr = createSaleAttr(bffGoodsInfo)
  }

  // 百亿补贴标签
  if (bffGoodsInfo.productMaterial?.brandDealsTitleLabel) {
    const brandDealsLabelData = createBrandDealsLabelData(bffGoodsInfo, options)
    goodsInfo.pretreatInfo.brandDealsTitleLabel = brandDealsLabelData.brandDealsTitleLabel
    goodsInfo.pretreatInfo.brandDealsAttributeLabel = brandDealsLabelData.brandDealsAttributeLabel
  } else if (bffGoodsInfo.productMaterial?.brandDealsAttributeLabel) {
    // 品类首金
    goodsInfo.pretreatInfo.brandDealsAttributeLabel = createBrandDealsLabelData(bffGoodsInfo, options).brandDealsAttributeLabel
  }

  // 降价标识
  if (Number(bffGoodsInfo.reducePrice?.amount || 0) > 0) {
    goodsInfo.pretreatInfo.reducePrice = createReducePrice(bffGoodsInfo, options)
  }

  // 收藏处理部分 end


  return goodsInfo
}

