import { parseQueryString } from '@shein/common-function'
import { ReplaceFootBar } from './ReplaceFootBar.jsx'


export const sameSellersReplacePlugin = ({ options}) => {
  let pluginContext = null
  const { cb } = options || {}
  /** 依赖数据 */
  const getRelationInfo = (context) => {
    const { mallCode, productInfo, skuInfo, saleAttr } = context
    const { storeInfo, priceInfo, language } = pluginContext?.useModules?.() || {}
    const { salePrice, retailPrice, unitDiscount } = priceInfo.value || {}

    const baseInfo = { ...productInfo, salePrice, retailPrice, unitDiscount }
    const skcSaleAttr = saleAttr?.multiLevelSaleAttribute?.skc_sale_attr || []
    const add_source = parseQueryString(location.search)?.add_source

    // 一键购的额外请求参数
    const extraOneClickPayParams = {
      sku_code: skuInfo?.sku_code || '',
      mall_code: mallCode,
      amount: salePrice?.amount || '',
      add_source: add_source ? `detail_${add_source}` : '',
    }

    return {
      ...context,
      baseInfo,
      language: language.value,
      storeInfo: storeInfo.value,
      skcSaleAttr,
      extraOneClickPayParams,
      // analysisConfig,
      // sourceIdentifier,
      // showAddCartBtn: !ocpBillNo
    }
  }

  return {
    name: 'SameSellersReplace',
    priority: 130,
    register: (context) => {
      pluginContext = context
    },
    beforeOpen: async context => {
      return false
    },
    renderAddCartButton: (context) => {
      const { isSoldOut, mallStock, unListed } = context
      const relationInfo =  getRelationInfo(context)
      // 非预售商品，且非售罄或者库存不为0
      if(!unListed && !(isSoldOut || mallStock == 0)) {
        return ReplaceFootBar({
          context,
          relationInfo,
          cb,
        })
      }
    }
  }
}