import OneClickPayBtn from 'public/src/pages/goods_detail_v2/innerComponents/top/AddToCartBar/OneClickPay/OneClickPayBtn.vue'
import './base.css'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { daEventCenter } from 'public/src/services/eventCenter/index'
daEventCenter.addSubscriber({ modulecode: '1-6-1' })
daEventCenter.addSubscriber({ modulecode: '1-8-1' })

export function Button(data) {
  const { 
    oneClickPayInfo, 
    relationInfo, 
    ocpCallBack
  } = data || {}
  const { billInfo } = oneClickPayInfo || {}
  const { 
    baseInfo, 
    language, 
    quantity, 
    skuInfo,
    addCartClick, 
    getReportGaList,
    getVariantAttr,
    reportSaAddCartEvent,
    closeQuickAddCart,
    skcSaleAttr, 
    extraOneClickPayParams,
    analysisConfig,
    sourceIdentifier,
    showAddCartBtn,
  } = relationInfo || {}

  const btnType = 'one_tap_pay'

  // 支付埋点数据
  const analysisData = {
    location: 'popup',
    order_no: !showAddCartBtn ? billInfo?.billno : '',
    activity_from: !showAddCartBtn ? 'one_tap_pay' : '',
  }

  // 一键购按钮点击时的检测
  const testBeforeOCP = () => {
    const hasAttrs = skcSaleAttr?.length
    
    if (hasAttrs && !skuInfo?.sku_code) {
      const unSelectedAttrs = []
      skcSaleAttr?.forEach?.(item => {
        unSelectedAttrs.push(item.attr_name)
      })
      
      SToast(`${language.SHEIN_KEY_PWA_19270} ${unSelectedAttrs.join('/')}`)
      
      daEventCenter.triggerNotice({
        daId: '1-6-1-91',
        extraData: {
          goods_id: baseInfo?.goods_id,
          activity_from: 'main',
          button_type: 'one_tap_pay',
        },
      })
      return false
    }
    return true
  }

  // 一键购支付成功回调
  const onOneClickPaySuccess = (res) => {
    reportSaAddCartEvent({
      result: res?.response || { code: 0 },
      gaList: getReportGaList(analysisConfig, baseInfo.cat_id),
      variant: getVariantAttr(skuInfo || {}),
      btnType: 'one_tap_pay'
    })
    setTimeout(() => {
      closeQuickAddCart()
    }, 2e2)
  }

  // 一键购支付失败回调
  const onOneClickPayFail = () => {
    // 支付失败直接加车
    addCartClick(btnType)
    // 上报加车事件
    reportSaAddCartEvent({
      result: { code: 1 },
      btnType
    })
  }
  
  // 一键购支付会话交互结束
  const onOneClickPayTransComplete = (type, extraData) => {
    const { result = '' } = extraData || {}
    ocpCallBack && ocpCallBack(type, result)
  }

  const scene = sourceIdentifier === 'detailPage' ? 'ProductDetail' : ''

  return (
    <div class="oneClick-button-box">
      {/* 默认加车按钮 */}
      { showAddCartBtn && (
        <button
          class="default-add-button"
          ole="button"
          aria-label={data.text}
          onClick={() => addCartClick()}
        >
          <span>{language?.SHEIN_KEY_PWA_15019  || 'ADD TO CART'}</span>
        </button>
      )}

      {/* 一键购按钮 */}
      <OneClickPayBtn
        class="plugin-ocp-button"
        show
        loadImmediately
        scene={scene}
        billInfo={billInfo}
        goodsData={baseInfo}
        quantity={quantity}
        extraPayParams={extraOneClickPayParams}
        beforePay={testBeforeOCP}
        language={language}
        analysisData={analysisData}
        onPaySuccess={onOneClickPaySuccess}
        onPayFail={onOneClickPayFail}
        onTransComplete={onOneClickPayTransComplete}
      />
    </div>
  )
}
