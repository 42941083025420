export function Button(props) {
  function handleClick() {
    props.addCartClick(undefined, {
      additionalProducts: {
        product_list: [
          {
            sku_code: props.selectedProduct?.sku_code,
            mall_code: props.selectedProduct?.mall_code,
            quantity: '1',
          },
        ],
      },
    })
  }
  return (
    <>
      <button
        class="bsc-quick-add-cart-standard__button_black"
        role="button"
        aria-label="Buy In Hand"
        onClick={handleClick}
      >
        {props.text}
      </button>
    </>
  )
}
