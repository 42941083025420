import { h } from 'vue'
import './base.css'

export function ReplaceFootBar(data) {

  const { relationInfo, context, cb } = data || {}
  const { replaceItem } = cb || {}
  const {
    language,
    addCartClick,
  } = relationInfo || {}
  const onClickReplace = async () => {
    const { checkSelected, setLoading, lockPage, removeLockPage, closeQuickAddCart } = context
    if (!checkSelected()) return
    try {
      // 锁定页面
      lockPage()
      setLoading(true)
      if (replaceItem) {
        await replaceItem(context)
      }
      setLoading(false)
      // 解锁页面
      removeLockPage()
      closeQuickAddCart()
    } catch (e) {
      console.log('salesAttrUpdatePlugin updateCallback error:', e)
      setLoading(false)
      // 解锁页面
      removeLockPage()
      closeQuickAddCart()
    }
  }
  return (
    <div class="sameSellersReplace-button-box">
      {/* 默认加车按钮 */}
      {(
        <button
          class="default-add-button"
          role="button"
          aria-label={data.text}
          onClick={() => addCartClick()}
        >
          <span>{language?.SHEIN_KEY_PWA_15019  || 'ADD TO CART'}</span>
        </button>
      )}

      {/* 同款低价商品替换按钮 */}
      <button
        class="mshe-bton-black replace-btn"
        role="button"
        aria-label="Replace"
        onClick={() => onClickReplace()}
      >
        <span>{ language?.SHEIN_KEY_PWA_25493 || 'Replace'}</span>
      </button>
    </div>
  )
}