/**
 * 快车弹窗交互状态
 */
export let QuickAddCartState = {}

/**
 * 加车弹窗数据同步(全局使用) 
 */
export const syncGlobalDataPlugin = () => {
  const store = window?._gb_app_?.$store || null
  // 快车弹窗初始交互状态
  const initQuickAddCartState = {
    isClickDetails: false,    // 是否点击跳转商详情
    isClickAddCartBtn: false, // 是否点击加车
    addCartStatus: false,     // 加车状态
    isClickWishBtn: false,  // 是否点击收藏
    wishStatus: false,    // 收藏状态
  }

  return {
    name: 'syncGlobalData',
    beforeOpen: async () => {
      QuickAddCartState = { ...initQuickAddCartState }
      return false
    },
    beforeClose: async () => {
      return QuickAddCartState
    },
    callbacks: {
      onAddCartFinished: (data) => {
        // 加车后，更新加车次数
        store && store.commit('newProductDetail/BagData/updateIsAddCartInDetail', true, { root: true })
        store && store.commit('newProductDetail/BagData/updateAddBagCountOnTheDay', false, { root: true })
        QuickAddCartState.isClickAddCartBtn = true
        QuickAddCartState.addCartStatus = data?.isSuccess
      },
      onClickAddToBoard: ({ productInfo = {} }) => {
        if (productInfo && productInfo.goods_id) {
          window?._gb_app_?.$_WD?.({
            type: 6,
            params: {
              goodsIds: [productInfo.goods_id],
            }
          })
        }
      },
      onJumpDetailClick: () => {
        QuickAddCartState.isClickDetails = true
      },
      onFavoriteStatusChange: ({ wishStatus }) => {
        QuickAddCartState.isClickWishBtn = true
        QuickAddCartState.wishStatus = wishStatus
      }
    }
  }
}
