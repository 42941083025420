import { daEventCenter } from 'public/src/services/eventCenter/index'

// 加车
export const CLICK_ADD_BAG = 'click_add_bag.comp_quick-add-cart-standard'
// 收藏
export const CLICK_ADD_COLLECT = 'click_add_collect.comp_quick-add-cart-standard'

/**
 * 自定义埋点事件插件
 */
export const definableEventPlugin = (configs = {}) => {
  const eventList = [
    CLICK_ADD_BAG,
    CLICK_ADD_COLLECT,
  ]

  return {
    name: 'definableEvent',
    beforeTriggerNotice: async ({ id = '', data = {} }) => {
      const config = configs[id]
      // 点击收藏
      if (id === CLICK_ADD_COLLECT) {
        daEventCenter.triggerNotice({
          daId: '1-8-1-2',
          target: data.target || null,
          extraData: data
        }) 
      }

      // 点击加车
      if (id === CLICK_ADD_BAG) {
        if(location?.hash === '#comment-all') data.page_name = 'page_goods_reviews'
        if (config?.appendExtraData) Object.assign(data, config.appendExtraData)
        daEventCenter.triggerNotice({
          daId: '1-8-1-1',
          target: data.target || null,
          extraData: data
        })
      }
      // 匹配到拦截event，覆盖加车组件默认上报
      return eventList.includes(id)
    },
  }
}
