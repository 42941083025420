import BuyNowButton from './BuyNowButton.vue'
export function Button(props) {
  return (
    <>
      <BuyNowButton
        checkoutNo={props.checkout_no}
        needJump={props.needJump}
        autoGetCoupon={props.autoGetCoupon}
        autoUpdateCouponList={props.autoUpdateCouponList}
        callback={props.onBuyNowFinished}
        analysisConfig={props.analysisConfig}
        text={props.text}
        context={props.context}
        useModules={props.useModules}
      ></BuyNowButton>
    </>
  )
}
