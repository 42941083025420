import BelowSalesAttrGift from './BelowSalesAttrGift'

export function Gifting(data) {

  return (
    <>
      <BelowSalesAttrGift context={data.context} pluginContext={data.pluginContext} giftInfo={data.giftInfo} selectGiftList={data.selectGiftList} updateGiftInfo={data.updateGiftInfo} />
    </>
  )
}
