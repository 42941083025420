// import { useQuickAddCart } from '@shein-aidc/bs-quick-add-cart-standard-mobile'
import { getStaticOptionsByScene } from './optionsConfig'
export { plugins } from './plugins/index'
export { canUseNewQuickAdd } from './switchConfig'
export { SCENE_MAP } from './optionsConfig'

const quickAddCart = {
  pending: false,
  instance: null,
}
let curScene = null

/**
 * 加车初始化
 * @param {String} scene 场景key
 * @param {Object} data 静态数据
 * @description 静态配置可在初始化设置
 */
export async function useQuickAddCartInstance(scene = '', data = {}) {

  if (typeof window !== 'undefined' && typeof gbCommonInfo !== 'undefined' && gbCommonInfo?.NODE_SERVER_ENV !== 'production') {
    console.log(
      `%c🚀 NEW_QUICK_ADD 🚀 `, 
      'font-family:sans-serif;font-size:1.2em; color:white; font-weight:bold; background: linear-gradient(#E47AFF, 26%, #FF0080); border-radius: 4px; padding: 4px',
      '场景: ',
      scene,
    )
  }

  if (!quickAddCart.instance) {
    // 静态配置
    const options = getStaticOptionsByScene(scene, data)
    // eslint-disable-next-line
    quickAddCart.instance = await new Promise(resolve => {
      import('@shein-aidc/bs-quick-add-cart-standard-mobile').then(res =>{ 
        resolve(res?.useQuickAddCart?.(options))
      })
    })
  }

  // 空场景值（默认场景）或场景不同更新配置
  if (scene === '' || (curScene !== null && curScene !== scene) ) {
    const options = getStaticOptionsByScene(scene, data)
    quickAddCart.instance?.updateOptions(options)
  }

  // 更新场景
  curScene = scene
  return quickAddCart.instance
}

export function getQuickAddCartInstance(scene = '', data = {}) {
  return {
    open: async (params, options) => {
      // 防止多次初始化
      if (quickAddCart.pending) return
      quickAddCart.pending = true
      try {
        const instance = await useQuickAddCartInstance(scene, data)
        instance && instance.open(params, options)
      } finally {
        // eslint-disable-next-line require-atomic-updates
        quickAddCart.pending = false
      }
    },
    close: async () => {
      const instance = await useQuickAddCartInstance(scene, data)
      instance &&  instance.close()
    }
  }
}

