import { deepMerge } from '@shein/common-function'
import { plugins } from './plugins/index'
import { callApp } from '../../../common/analysis/callApp'
export const SCENE_MAP = {
  /** 商详主加车 */
  DETAILS: 'DETAILS',
  /** 商详推荐 */
  DETAILS_REC: 'DETAILS_REC',
  /** 商详主销售属性选择弹窗 */
  DETAILS_ATTR: 'DETAILS_ATTR'
}

/** 默认配置 */
const defaultOptions = () => ({
  plugins: [
    plugins?.findSimilarPlugin(), 
    plugins.syncCartInfoPlugin(),
    plugins.syncGlobalDataPlugin(), 
    plugins.definableEventPlugin(), 
    plugins.onMonitoringPlugin(),
  ],
  directives: {
    'callApp': callApp
  }
})

/** 商详主加车配置 */
const detailsOptions = () => ({
  plugins: [
    plugins.syncDetailsDataPlugin(), 
    plugins.estimatedTipsPlugin(),
    plugins.syncCartInfoPlugin({ disabledCartTagTip: true, disabledTriggerFloatCart: true }),
  ],
  salesAttrConfig: {
    showSizeRecommend: true,
  }
})

/** 商详推荐配置 */
const detailsRecOptions = () => ({
  plugins: [
    plugins.syncDetailsDataPlugin(),
    plugins.oneClickPayPlugin(),
    plugins.syncCartInfoPlugin({ disabledCartTagTip: true, disabledTriggerFloatCart: true }),
  ]
})

/** 商详销售属性选择弹窗 (跟商详推荐动态配置有差异)*/
const detailsAttrOptions = () => ({
  plugins: [
    plugins.syncDetailsDataPlugin(),
    plugins.syncCartInfoPlugin({ disabledCartTagTip: true, disabledTriggerFloatCart: true }),
  ]
})

// 静态配置映射
const optionsMap = {
  [SCENE_MAP.DETAILS]: detailsOptions,
  [SCENE_MAP.DETAILS_REC]: detailsRecOptions,
  [SCENE_MAP.DETAILS_ATTR]: detailsAttrOptions,
}

/** 获取场景静态配置 */
export const getStaticOptionsByScene = (scene, data) => {
  try {
    // 场景配置
    const sceneOptions = optionsMap?.[scene]?.(data) || {}
    // 合并配置
    return deepMerge({ a: defaultOptions(), b: sceneOptions, isWith: true })
  } catch (error) {
    return defaultOptions()
  }
}
