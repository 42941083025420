export class LRUCache {
  constructor(maxCacheSize, ttl) {
    this.cache = new Map()
    this.maxCacheSize = maxCacheSize
    this.ttl = ttl
  }
  get(key) {
    const item = this.cache.get(key)
    if (item) {
      // 检查缓存项是否过期（以存储时间为基准）
      if (Date.now() - item.timestamp < this.ttl) {
        return item.value
      } else {
        this.cache.delete(key)
      }
    }
    return undefined
  }
  set(key, value) {
    if (this.cache.size >= this.maxCacheSize) {
      // 删除最旧的缓存项
      const oldestKey = this.findOldestKey()
      this.cache.delete(oldestKey)
    }
    this.cache.set(key, { value, timestamp: Date.now() })
  }
  has(key) {
    return this.cache.has(key) && this.get(key) !== undefined
  }
  findOldestKey() {
    let oldestKey = ''
    let oldestTimestamp = Infinity
    for (const [key, item] of this.cache) {
      if (item.timestamp < oldestTimestamp) {
        oldestKey = key
        oldestTimestamp = item.timestamp
      }
    }
    return oldestKey
  }
  /**
   * 根据相似的 key 删除缓存项
   * @description 只允许用goods id删除缓存项
   * @param key
   */
  deleteBySimilarKey(key) {
    if (key) {
      for (const similarKey of this.cache.keys()) {
        if (similarKey.includes(key)) {
          this.cache.delete(similarKey)
        }
      }
    }
  }
  clear() {
    this.cache.clear()
  }
}
