import BuyInHand from './BuyInHand.vue'
export function BuyInHandBox(props) {
  return (
    <>
      <BuyInHand
        language={props.language}
        complianceMode={props.complianceMode}
        catId={props.useModules?.()?.productInfo?.value?.cat_id}
        goodsId={props.useModules?.()?.productInfo?.value?.goods_id}
        goodsSn={props.useModules?.()?.productInfo?.value?.goods_sn}
        skuCode={props.skuInfo?.sku_code}
        mallCode={props.mallCode}
        isPaidMember={props.useModules?.()?.userInfo?.value?.isPaidMember}
        isAddToCart={true}
        productCardDeps={props.productCardDeps}
        selectedProduct={props.selectedProduct}
        updateSelectedProduct={props.updateSelectedProduct}
      ></BuyInHand>
    </>
  )
}
