
/**
 * 加车成功后业务(弹窗 / 滚动)
 */
export const addCartRecommendPlugin = (addCartRecommendPluginData) => {
  let pluginContext = null
  const store = window?._gb_app_?.$store || {}
  // 是否滑动到评论页推荐
  const {  needScrollReviewYmal = false } = addCartRecommendPluginData || {}

  // 主销售属性数据
  let curExtraMainAttrData = {}

  // 是否需要滚动(弹窗会锁屏需要关闭后滚动)
  let showScroll = false

  /**
   * 滑动到商详情页推荐
   * @param {boolean} force 是否强制滑动到推荐
   */
  const setGoodsDetailScroll = (force) => {
    store.commit('productDetail/CommentPopup/update_need_scroll_review_ymal', needScrollReviewYmal, { root: true })
    if (!force && JSON.stringify(curExtraMainAttrData) !== '{}') {
      store.commit('newProductDetail/common/updateAsyncScrollFromReview', false, { root: true })
      store.commit('newProductDetail/common/updateAsyncScrollStatus', true, { root: true })
    } else {
      store.dispatch('productDetail/Recommend/set_goods_detail_scroll', {}, { root: true })
    }
  }

  // 校验是否SPU
  const checkSpu = () => {
    const productRelationId = store.getters['productDetail/common/product_relation_id']
    const productInfo = pluginContext?.useModules?.()?.productInfo.value || {}
    return productRelationId === productInfo?.productRelationID
  }

  return {
    name: 'addCartRecommend',
    register: (context) => {
      pluginContext = context
    },
    afterAddCartApi: async (context) => {
      const { addCartResult, setHiddenAddCartTips, productInfo, mallCode, skuInfo } = context
      const { addbagRecQuickShow, addbagRecAllShow } = store.getters['productDetail/Recommend/add_bag_recommend_config']
      const { needDelayScroll = false, needScrollReviewYmal } = addCartRecommendPluginData || {}
      // 非主商品spu / 加车失败
      if (!checkSpu() || addCartResult?.code !== '0') return

      // 加车成功后存储当前商品的主要信息
      const reqParams = {
        goods_id: productInfo.goods_id,
        quantity: addCartResult?.info?.quantity,
        mall_code: mallCode,
        sku_code: skuInfo?.sku_code || '',
        attrs: skuInfo?.sku_sale_attr || [],
      }
      store.dispatch('productDetail/addBag/update_add_to_bag_info', reqParams)
      store.dispatch('productDetail/addBag/show_outfit_content')

      // 加车成功后操作（弹窗 / 滚动到底下推荐）
      const afterAddCardRecommendAction = async () => {
        // 实验控制是否展示加车成功后推荐弹窗
        const need_fetch_rec_data_actions = addbagRecQuickShow || addbagRecAllShow
        await store.dispatch?.('productDetail/addBag/after_add_cart_req', { need_fetch_rec_data_actions: need_fetch_rec_data_actions }, { root: true })
        if(need_fetch_rec_data_actions) {
          const status = await store.dispatch?.('productDetail/Recommend/open_add_bag_recommend_drawer', null, { root: true })
          setHiddenAddCartTips(status)
          return !!status
        }
        return false
      }

      // 是否需要延迟
      if (needDelayScroll && !needScrollReviewYmal && window?._gb_app_) {
        await afterAddCardRecommendAction()
        // 监听评论页回到商详
        const unWatch = window._gb_app_.$watch(() => _gb_app_?.$store.state.productDetail.CommentPopup.view_comment, async(newVal) => {
          if (!newVal) {
            unWatch?.()
            // 延迟滚动
            const timer = setTimeout(() => {
              clearTimeout(timer)
              setGoodsDetailScroll()
            }, 500)
          }
        })
      } else if (JSON.stringify(curExtraMainAttrData) !== '{}' && !needScrollReviewYmal) {
        const status = await afterAddCardRecommendAction()
        if (!status) {
          // 切换skc需要等待 all data ready
          window.vBus && window.vBus.on('allDateReady', () => {
            setGoodsDetailScroll(true)
            window.vBus.off('allDateReady')
          })
        }
      }else {
        const status = await afterAddCardRecommendAction()
        showScroll = !status
      }
    },
    callbacks: {
      onMainAttrChange: (mainAttr = {}) => {
        curExtraMainAttrData = mainAttr
      },
      onDrawerStatusChange: (isShow) => {
        if (isShow) {
          // 复位
          curExtraMainAttrData = {}
          showScroll = false
          return
        }
        // 关闭弹窗后滚动
        const timer = setTimeout(() => {
          clearTimeout(timer)
          showScroll && setGoodsDetailScroll()
        }, 500)
      }
    }
  }
}
