<script lang="jsx">
import { defineComponent, withModifiers } from 'vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { SCheckbox } from '@shein-aidc/sui-checkbox/mobile'
// utils
import { views, bffViews } from './js/constant'
// mixins
import productCardMixin from './mixins/productCardMixin'

export default defineComponent({
  name: 'SingleImageRowCard',
  components: {
    SCheckbox
  },
  mixins: [productCardMixin],
  emits: ['clickAddToBoard', 'clickDelete', 'clickSearchFilter'],
  props: {
    /**
     * @param {import('../types').ProductCardConfig} config
    */
    config: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      labelsFromKey: views.LIST_MOBILE_BROADWISE_VIEW,
      bffLabelsFromKey: bffViews.LIST_MOBILE_BROADWISE_VIEW,
    }
  },
  render() {
    // config
    const { showCardLabelsContainer = true, hideSoldOut, autoCropImage, showAddBagBtnBottomRight, style = {}, showHorseRaceLabel, showLabelCarousel = false, showCategoryViewStyle = false } = this.config || {}

    const showAddBagBtn = this.config.showAddBagBtn && !(this.item.bffGoodsInfo && this.item.showAddButtonLabel === '0')
    
    const { 
      mainImg,
      isSoldOut,
      showMoreBtn,
      hasFeedback,
      comingSoon,
      showBestDeal,
      followLabelVisible,
      beltLabel,
      exclusivePromotionPrice,
      showDeliveryWords,
      showCamelPrice,
      showSelling,

      cornerPropertyUpsell,
      hasLowerRightMaterial,
      isShowLowerRightColorBlock,
      ItemLocateLabels,
      ProductCardImgLeftTop,
      ProductCardImgRightTop,
      ProductCardImgLeftBottom,
    } = this.handleRender()

    
    const checkedBoxStyle = style?.checkedBoxStyle ?? ''
    
    return (
      <div
        v-show={this.status.showSelf}
        ref="productCardRef"
        class={`product-card single-row-item ${this.selectStatus ? 'single-row-item--selected' : ''}`}
      >
        {/* 选择模式(主图左边) */}
        {this.showSelect && this.selectPosition === 'mainLeft' ? (
          <div
            class="product-card__ctrl"
            onClick={withModifiers(this.handleSelect, ['prevent'])}
          >
            <s-checkbox
              modelValue={this.selectStatus}
              class={['product-card__select', checkedBoxStyle]}
              gap="0"
            />
          </div>
        ) : null}
        {/* 主图区域 */}
        <div
          class="product-card__top-wrapper"
          onTouchstart={this.touchstart}
          onTouchmove={this.touchmove}
          onTouchend={this.touchend}
          onContextmenu={withModifiers(() => {}, ['prevent'])}
          onClick={withModifiers((e) => this.clickItem(e, { from: 'mainImg' } ), ['prevent'])}
          onPointerover={withModifiers(this.handlePointerover, ['prevent'])}
        >
          <div class="top-wrapper__main-img">
            {/* 主图区域 */}
            <ProductCardImg 
              mainImg={mainImg} 
              column={this.column} 
              onImgLoadFinish={this.onImgLoadFinish}
              index={this.index} 
              autoCropImage={autoCropImage} 
              cropRate={this.cropRate}
              lcp={this.lcp}
            />
            {/* 主图蒙层 */}
            <ProductCardMaskLayer
              mainImg={mainImg}
              column={this.column}
              cropRate={this.cropRate}
              autoCropImage={autoCropImage} 
            />
            <ClientOnly>
              {/* 定位角标 */}
              { ItemLocateLabels() }
            </ClientOnly>
          </div>

          {/* 主图下方区域 */}
          <div class="top-wrapper__bottom">
            <div class="top-wrapper__bottom-middletop">
              {/* 主图左下区域 */}
              <div class="bottom-middletop__top-left">
                <ClientOnly>
                  {ProductCardImgLeftBottom()}
                </ClientOnly>
              </div>

              {/* 撑开左右两个节点 */}
              {
                <div class="bottom-middletop__top-middle"></div>
              }

              {/* 主图右下区域 */}
              <div class="bottom-middletop__top-right">
                {
                  showAddBagBtnBottomRight ?
                    (
                      <ProductItemAddToBag
                        box-shadow={false}
                        size="small"
                        custom-style={this.addToBagStyle}
                        onOpenQuickAdd={this.handleOpenQuickAdd} 
                        is-rw={this.IS_RW}
                      />
                    )
                    : (
                      <ProductCardImgRightBottom
                        beltLabel={beltLabel}
                        getCornerPropertyUpsell={cornerPropertyUpsell}
                        hasLowerRightMaterial={hasLowerRightMaterial}
                        isShowLowerRightColorBlock={isShowLowerRightColorBlock}
                        relatedColor={this.relatedColor}
                      />
                    )
                }
              </div>
            </div>

            {/* 主图下方中位区域 */}
            <div class="top-wrapper__bottom-middle">
              <ClientOnly>
                <ProductCardImgBottom
                  coming-soon={comingSoon}
                  is-sold-out={isSoldOut}
                  show-best-deal={showBestDeal}
                  follow-label-visible={followLabelVisible}
                  belt-label={beltLabel}
                  save-amount={this.saveAmount}
                >
                  {/* 图片底部slot(嵌套) */}
                  {{
                    imgBottom: this.$slots.imgBottom,
                    imgBottomInTop: this.$slots.imgBottomInTop,
                  }}
                  {/* 图片底部slot(盖在腰带之上) */}
                </ProductCardImgBottom>
              </ClientOnly>
            </div>
          </div>

          {/* 主图左上区域 */}
          <div class="top-wrapper__top-left">
            <ClientOnly>
              {ProductCardImgLeftTop()}
              { showLabelCarousel ? null : this.$slots.imgTopLeft?.() }
            </ClientOnly>
          </div>

          {/* 主图右上区域 */}
          <div class="top-wrapper__top-right">
            <ClientOnly>
              {ProductCardImgRightTop()}
            </ClientOnly>
          </div>


          {/* 售罄背景slot，默认展示Sold Out */}
          {
            this.$slots.soldout?.()  || (
              !hideSoldOut && isSoldOut && !comingSoon && !this.soldoutOperationPanelShow
                ? (
                  <ProductItemSoldout
                    cur-data={this.curData}
                    language={this.language}
                    constant-data={this.constantData}
                    config={this.config}
                    is-sold-out={isSoldOut}
                    showSimilarPopup={this.showSimilarPopup}
                    index={this.index}
                    show-select={this.showSelect}
                    onClickAddToBoard={(payload) => this.$emit('clickAddToBoard', payload)}
                    onClickDelete={(payload) => this.$emit('clickDelete', payload)}
                    onSelect={this.handleSelect}
                    onClickItem={() => this.clickItem(null, { from: 'soldOut' } )}
                    onPointerover={withModifiers(this.handlePointerover, ['prevent'])}
                  />
                )
                : null
            )
          }
        </div>

        {/* 主要信息区域（价格，标题，标签等）*/}
        <div 
          class={[ 
            'product-card__bottom-wrapper',
            {
              'product-card__bottom-wrapper_soldout': isSoldOut && !showCategoryViewStyle,
              'product-card__bottom-wrapper_categoryviewsoldout': isSoldOut && showCategoryViewStyle,
              'product-card__bottom-wrapper_categoryview': showCategoryViewStyle,
            } 
          ]}
          onClick={withModifiers((e) => this.clickItem(e, { from: 'cardInfo' } ), ['prevent'])}
          onPointerover={withModifiers(this.handlePointerover, ['prevent'])}
        >

          {/* 趋势词: 标题上 */}
          {showDeliveryWords && <DeliveryWords
            goodsId={this.curData?.goods_id}
            catId={this.curData?.cat_id}
            extendToChannel={this.extendToChannel}
            language={this.language}
            deliveryInfo={this.curData?.deliveryInfo ?? {}}
            storeInfo={this.curData?.storeInfo ?? {}}
            index={this.index}
            customCateIds={this.curData?.customCateIds ?? ''} // 这个字段是趋势频道混入进来的
            isModStoreTag={this.curData?.storeInfo?.isModStore}
            isChoiceStoreTag={this.curData?.storeInfo?.isChoiceStore}
            isChoice={!!this.curData?.deliveryInfo?.dpPoolId}
            config={this.config}
            style="height: auto;"
          ></DeliveryWords>}

          { this.$slots.cardTitleTop?.() }

          {/* 标题区域 */}
          <ProductItemTitleContainer />

          {/* 榜单卖点信息区域 或 赛马标签信息区域 */}
          {
            showSelling && showHorseRaceLabel ? (
              <div class="bottom-wrapper__selling-info">
                <ProductCardHorseRaceLabelContainer />
              </div>
            ) : null
          }

          {
            showSelling && !showHorseRaceLabel ? (
              <div class="bottom-wrapper__selling-info">
                <ProductCardSellingPropositionContainer />
              </div>
            ) : null
          }

          { this.$slots.cardPriceTop?.() }

          <div class="bottom-wrapper__price-wrapper">
            {/* 价格 */}
            {
              !this.config.hideAllBottomPrices ? 
                <ProductCardPrices
                  class="price-wrapper__price"
                  show-camel-price={showCamelPrice}
                  exclusive-promotion-price={exclusivePromotionPrice}
                  has-feedback={hasFeedback}
                  show-best-deal={showBestDeal}
                  belt-label={beltLabel}
                >
                  {/* 价格slot */}
                  {{ price: this.$slots.price }}
                </ProductCardPrices> : null
            }
            {
              showCategoryViewStyle ? 
                showMoreBtn && this.$slots.moreInfoBtn ? (
                  <div 
                    class="more-btn-wrapper" 
                    onClick={withModifiers(this.handleClickMoreBtn, ['stop'])}
                  >
                    { this.$slots.moreInfoBtn?.() }
                  </div>
                ) : null : null
            } 
          </div>
          
          <div class="bottom-wrapper__labels">
            { this.$slots.reducePrice?.() || null }
            {
              showCategoryViewStyle ? null : 
                <div class="labels-row-container">
                  {
                    showLabelCarousel ? 
                      <div class="left-block-item">{ this.$slots.labelCarousel?.() }</div> :
                      [this.$slots.wishNum?.(), this.$slots.stockLeft?.()]
                        .filter(_ => _)
                        .map(slotItem => <div class="left-block-item">{ slotItem }</div>) 
                  }
                  { 
                    showMoreBtn && this.$slots.moreInfoBtn ? (
                      <div 
                        class="more-btn-wrapper" 
                        onClick={withModifiers(this.handleClickMoreBtn, ['stop'])}
                      >
                        { this.$slots.moreInfoBtn?.() }
                      </div>
                    ) : null 
                  }
                </div>
            }
            {/* 服务类标签 和 分布式筛选标签 */}
            {showCardLabelsContainer ? <ProductCardLabelsContainer 
              onClickSearchFilter={ (e) => { this.$emit('clickSearchFilter', e) } } 
            >
              {{ goodsServiceTag: this.$slots.goodsServiceTag }}
            </ProductCardLabelsContainer> : null}
          </div>

          {/* 商卡右边底部加车 */}
          {
            this.$slots.cardBottomAddCardLeft?.() || (showAddBagBtn && !showAddBagBtnBottomRight) ?
              (
                <div class="bottom-wrapper__footer">
                  <div class="footer-content-left">
                    { this.$slots.cardBottomAddCardLeft?.() }
                  </div>
                  {/* 加车 */}
                  {
                    showAddBagBtn && !showAddBagBtnBottomRight
                      ? (
                        <ProductCardAddBagBtn 
                          class="footer__addbag-btn"
                          loading={this.loading}
                          onOpenQuickAdd={this.handleOpenQuickAdd} 
                        />
                      )
                      : null
                  }
                </div>
              ) : null
          }

          { this.$slots.goodsBottom?.() || null }
          {/* 加车按钮下方 */}
          { this.$slots.cardBottom?.() || null }
        </div>
        {/* 商品主体slot */}
        { this.$slots.goodsMain?.() || null }
      </div>
    )
  },
})
</script>

<style lang="less">
@import url(./style/checkBox.less);
@import url('./style/icon.less');

.single-row-item {
  --property-upsell-text-size: 7px;
  padding-right: .213rem;
  width: 100%;

  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;

  background-color: var(--product-card-bg, #fff);
  background-image: var(--product-card-bg-image, none);

  // 主图区域 
  .product-card__top-wrapper {
    flex: 0 0 2.56rem;
    position: relative;
    overflow: hidden;

    border-radius: var(--product-card-img-radius, 0);
    .top-wrapper {
      // 主图下方区域
      &__bottom {
        position: absolute;
        bottom: 0;
        width: 100%;
      }
      // 主图下方中位区域上
      &__bottom-middletop {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }
      // 主图左上区域
      &__top-left {
        position: absolute;
        left: 0;
        top: 0;
        max-width: 100%;
      }
      // 主图右上区域
      &__top-right {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }

  .product-card__soldout-content {
    p {
      .font-dpr(24px);
    }
  }

  // 主要信息
  .product-card__bottom-wrapper {
    position: relative;

    margin-left: .213rem;
    padding: .213rem 0;
    flex: 1 1 auto;

    overflow: hidden;

    display: flex;
    flex-direction: column;

    &_show-store {
      padding-bottom: 0;
    }
  }

  .product-card__bottom-wrapper_soldout {
    & > div, & > section {
      opacity: 0.3;
    }
  }

  .product-card__bottom-wrapper_categoryview{
    .more-btn-wrapper {
      position: absolute;
      right: 0;
      top: 0.12rem;
    }
  }
  .product-card__bottom-wrapper_categoryviewsoldout {
    .bottom-wrapper__price-wrapper {
      .price-wrapper__price {
        opacity: 0.3;
      }
    }
    .more-btn-wrapper {
      opacity: 1;
    }
    .productcard-belowlabel {
      opacity: 0.3;
    }
    .bottom-wrapper__labels {
      opacity: 0.3;
    }
  }

  .product-card__ctrl {
    padding: 0 .213rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .sui-checkbox__label-select {
      border: 0.04rem solid #767676;
    }
  }

  // 卖点信息区域
  .bottom-wrapper__selling-info {
    overflow: hidden;
    text-align: center;
  }
  // 价格区域
  .bottom-wrapper__price-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    // 价格布局
    .price-wrapper {
      &__price {
        flex: 1 1 auto;
        width: 0;
      }
    }

    .product-card__prices {
      & > div:not(.product-card__prices-info), & > p {
        margin-left: .106rem;
      }
    }
    .product-card__discount {
      align-self: baseline;
    }
  }

  // 服务类和分步式筛选标签
  .bottom-wrapper__labels {
    text-align: center;
  }

  .bottom-wrapper__footer {
    flex: 1 1 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .footer__addbag-btn {
      flex: 0 0 auto;
    }
  }
}
</style>
